.editor-container {
    margin-top: 10px;
}

.tox-editor-container {
    border: 1px solid transparent !important;
    border-radius: 10px;
}

.tox-editor-container:hover {
    border: 1px solid #F2DFCE !important;
}

.editor-container-error .tox-editor-container, .editor-container-error .tox-editor-container:hover {
    border: 1px solid #FF2D00 !important;
    border-radius: 10px;
}

.tox:not(.tox-tinymce-inline) .tox-editor-header, .tox .tox-statusbar {
    background-color: #FDF4EC !important;
    border: none !important;
}

.tox:not(.tox-tinymce-inline) .tox-editor-header {
    border-radius: 10px 10px 0 0;
}

.tox .tox-statusbar {
    border-radius: 0 0 10px 10px;
}

.tox .tox-tbtn svg, .tox .tox-statusbar__branding svg {
    fill: #1A1A1A !important;
    transition: fill 0.25s ease;
}

.tox .tox-tbtn svg:hover {
    cursor: pointer;
    fill: #999999 !important;
}

.tox .tox-tbtn--enabled {
    background: #F2DFCE !important;  
}

.tox .tox-statusbar__path-item, .tox .tox-statusbar__wordcount {
    color: #1A1A1A !important;
    fill: #1A1A1A !important;
}

.tox-tinymce-aux {
    z-index:99999999999 !important;
}

