html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: InterRegular;
  src: url('assets/fonts/Inter-Regular.otf') format('opentype');
}

@font-face {
  font-family: InterMedium;
  src: url('assets/fonts/Inter-Medium.ttf') format('opentype');
}

@font-face {
  font-family: InterSemiBold;
  src: url('assets/fonts/Inter-SemiBold.otf') format('opentype');
}

/* Scroll bar */

/* width */
*::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
*::-webkit-scrollbar-track {
  background-color: lightgray;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #1F1F1F;
  border-radius: 20px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
