.BD-SearchResultsContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-template-areas: 'suggested featured refine';
  box-sizing: border-box;
  gap: 24px;
}

@media only screen and (max-width: 850px) {
  .BD-SearchResultsContainer {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'suggested refine'
      'featured';
    justify-items: center;
    grid-row-gap: 16px;
  }

  .BD-SearchResultsContainer .suggested {
    grid-column: 1 / 2;
    grid-row: 1;
  }
  .BD-SearchResultsContainer .refine {
    grid-column: 2 / 4;
    grid-row: 1;
  }

  .BD-SearchResultsContainer .featured {
    grid-column: 1 / 3;
    grid-row: 2;
    max-width: calc(50% - 8px);
  }
}

@media only screen and (max-width: 600px) {
  .BD-SearchResultsContainer .featured {
    max-width: 100%;
  }
}

@media only screen and (max-width: 390px) {
  .BD-SearchResultsContainer {
    grid-template-columns: 1fr;
    grid-template-areas:
      'suggested'
      'refine'
      'featured';
    gap: 0px;
    row-gap: 16px;
  }

  .BD-SearchResultsContainer .suggested {
    grid-column: 1 / 2;
    grid-row: 1;
  }
  .BD-SearchResultsContainer .refine {
    grid-column: 1 / 2;
    grid-row: 2;
  }

  .BD-SearchResultsContainer .featured {
    width: 100%;
    grid-row: 3;
  }
}
